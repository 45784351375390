const legacyPrefixes = [
  '/build-with-rapyd/reference/',
  '/build-with-rapyd/docs/',
  '/build-with-rapyd/changelog/',
  '/works-with/docs/',
  '/client-portal/docs/',
];

const legacyPrefixesRegExp =
  /\/build-with-rapyd\/reference\/|\/build-with-rapyd\/docs\/|\/build-with-rapyd\/changelog\/|\/works-with\/docs\/|\/client-portal\/docs\//;

const pageNameMapper = {
  'rapyd-overview': 'rapyd-api-technical-information',
  'rapyd-platform': 'rapyd-payments-platform',
  webhooks: 'webhooks-364716',
  'quarantine-object': 'rapyd-protect',
  'webhook-quarantine-under-review': 'quarantine-under-review-webhook',
  'webhook-quarantine-declined': 'quarantine-declined-webhook',
  'webhook-quarantine-released': 'quarantine-released-webhook',
  'webhook-quarantine-release-failed': 'quarantine-release-failed-webhook',
  'rapyd-protect-errors-rapyd-authorization':
    'rapyd-protect-errors---rapyd-authorization',
  'rapyd-protect-errors-remote-authorization':
    'rapyd-protect-errors---remote-authorization',
  payment: 'payment-363017',
  'payment-object': 'payment-363017',
  'client-details-object': 'payment-363017',
  'outcome-object': 'payment-363017',
  'payment-fees-object': 'payment-363017',
  'payment-method-data-object': 'payment-363017',
  'wallets-array': 'payment-363017',
  'customizing-hosted-pages-with-google-pay':
    'customizing-hosted-pages-with-google-pay-',
  'creating-a-payment-with-google-pay': 'creating-a-payment-with-google-pay-',
  'creating-a-google-pay-payment-on-a-customized-checkout-page':
    'creating-a-google-pay--payment-on-a-customized-checkout-page',
  'creating-a-payment-with-applepay': 'creating-a-payment-with-apple-pay',
  'create-payment-notes': 'create-payment',
  'complete-payment': 'complete-payment-363014',
  'webhook-payment-captured': 'payment-captured-webhook',
  'webhook-payment-completed': 'payment-completed-webhook',
  'webhook-payment-expired': 'payment-expired-webhook',
  'webhook-payment-failed': 'payment-failed-webhook',
  'webhook-payment-reversed': 'payment-reversed-webhook',
  'webhook-payment-succeeded': 'payment-succeeded-webhook',
  'group-payment': 'group-payment-363561',
  'group-payment-object': 'group-payment-363561',
  'creating-a-group-payment': 'group-payment',
  'webhook-group-payment-completed': 'group-payment-completed-webhook',
  'digital-wallet-object': 'digital-wallet',
  escrow: 'escrow-363791',
  'escrow-object': 'escrow-363791',
  'webhook-escrow-released': 'escrow-released-webhook',
  refund: 'refund-364021',
  'refund-object': 'refund-364021',
  'creating-a-refund': 'creating-a-refund-365212',
  'webhook-refund-completed': 'refund-completed-webhook',
  'webhook-refund-failed': 'refund-failed-webhook',
  'webhook-refund-rejected': 'refund-rejected-webhook',
  dispute: 'dispute-364078',
  'dispute-object': 'dispute-364078',
  'webhook-dispute-created': 'dispute-created-webhook',
  'webhook-dispute-updated': 'dispute-updated-webhook',
  customer: 'customer-364250',
  'customer-object': 'customer-364250',
  'creating-a-customer-profile': 'customer',
  'list-customers': 'list-customers-364253',
  'webhook-customer-created': 'customer-created-webhook',
  'webhook-customer-updated': 'customer-updated-webhook',
  'webhook-customer-deleted': 'customer-deleted-webhook',
  'address-object': 'address',
  'payment-method-type-object': 'payment-method-type-364771',
  'options-object': 'payment-method-type-364771',
  'payment-options-object': 'payment-method-type-364771',
  'payment-method-options-object': 'payment-method-type-364771',
  'conditions-object': 'payment-method-type-364771',
  'operators-for-conditions': 'payment-method-type-364771',
  'conditions-examples': 'payment-method-type',
  'customer-payment-method': 'customer-payment-method-364773',
  'customer-payment-method-object': 'customer-payment-method-364773',
  'add-payment-method-sequences': 'customer-payment-method',
  'add-payment-method-sequence': 'customer-payment-method',
  'webhook-payment-method-canceled': 'payment-method-canceled-webhook',
  'webhook-card-added-successfully': 'card-added-successfully-webhook',
  'webhook-card-added-failure': 'card-added-failure-webhook',
  'webhook-customer-payment-method-created':
    'customer-payment-method-created-webhook',
  'card-token': 'card-token-364784',
  'card-token-object': 'card-token-364784',
  'product-object': 'product',
  'sku-object': 'sku',
  plan: 'plan-364800',
  'plan-object': 'plan-364800',
  subscription: 'subscription-364807',
  'subscription-object': 'subscription-364807',
  'webhook-new-cycle-started-collect': 'new-cycle-started-collect-webhook',
  'webhook-subscription-updated-collect':
    'subscription-updated-collect-webhook',
  'webhook-subscription-unpaid-collect': 'subscription-unpaid-collect-webhook',
  'webhook-subscription-canceled-collect':
    'subscription-canceled-collect-webhook',
  'webhook-trial-nearing-end': 'trial-nearing-end-webhook',
  'subscription-item-object': 'subscription-item',
  'subscription-hosted-page': 'subscription---hosted-page-364829',
  'subscription-object-hosted-page': 'subscription---hosted-page-364829',
  'subscription-hosted-page-message-sequences': 'subscription---hosted-page',
  'create-subscription-by-hosted-page': 'create-subscription-by-hosted-page',
  'usage-record-object': 'usage-record',
  'coupon-object': 'coupon',
  'discount-object': 'discount',
  order: 'order-364842',
  'order-object': 'order-364842',
  'items-object': 'order-364842',
  'webhook-order-payment-failed': 'order-payment-failed-webhook',
  'webhook-order-updated': 'order-updated-webhook',
  'return-object': 'return',
  invoice: 'invoice-364856',
  'invoice-object': 'invoice-364856',
  'webhook-invoice-created': 'invoice-created-webhook',
  'webhook-invoice-updated': 'invoice-updated-webhook',
  'webhook-invoice-finalized': 'invoice-finalized-webhook',
  'webhook-invoice-payment-created': 'invoice-payment-created-webhook',
  'webhook-invoice-payment-succeeded': 'invoice-payment-succeeded-webhook',
  'webhook-invoice-payment-failed': 'invoice-payment-failed-webhook',
  'invoice-item-object': 'invoice-item',
  'checkout-page-object': 'checkout-page',
  'custom-elements-object': 'checkout-page',
  'create-checkout-message-sequence-bank-redirect':
    'create-checkout-message-sequence---bank-redirect',
  'create-checkout-message-sequence-bank-transfer':
    'create-checkout-message-sequence---bank-transfer',
  'create-checkout-message-sequence-card':
    'create-checkout-message-sequence---card',
  'create-checkout-message-sequence-card-2-step':
    'create-checkout-message-sequence---card---2-step',
  'create-checkout-message-sequence-card-3ds':
    'create-checkout-message-sequence---card---3ds',
  'create-checkout-message-sequence-cash':
    'create-checkout-message-sequence---cash',
  'create-checkout-message-sequence-escrow':
    'create-checkout-message-sequence---escrow',
  'create-checkout-message-sequence-local-ewallet':
    'create-checkout-message-sequence---local-ewallet',
  'payment-link-object': 'payment-link',
  'checkout-object': 'payment-link',
  payout: 'payout-365010',
  'payout-object': 'payout-365010',
  'payout-fees-object': 'payout-365010',
  'webhook-payout-canceled': 'payout-canceled-webhook',
  'webhook-payout-completed': 'payout-completed-webhook',
  'webhook-payout-created': 'payout-created-webhook',
  'webhook-payout-expired': 'payout-expired-webhook',
  'webhook-payout-failed': 'payout-failed-webhook',
  'webhook-payout-returned': 'payout-returned-webhook',
  'webhook-payout-updated': 'payout-updated-webhook',
  'payout-method-type-object': 'payout-method-type',
  'payout-options-object': 'payout-method-type',
  'sender-object': 'sender',
  'beneficiary-object': 'beneficiary',
  'beneficiary-tokenization-page-object': 'beneficiary-token',
  'beneficiary-optional-fields-object': 'beneficiary-token',
  'webhook-beneficiary-created': 'beneficiary-created-webhook',
  'service-provider-object': 'service-provider',
  'webhook-service-provider-payment-completed':
    'service-provider-payment-completed-webhook',
  'rapyd-wallet-overview': 'rapyd-wallet-368670',
  wallet: 'wallet-365049',
  'wallet-object': 'wallet-365049',
  'webhook-wallet-enabled': 'wallet-enabled-webhook',
  'webhook-wallet-disabled': 'wallet-disabled-webhook',
  'webhook-wallet-deleted': 'wallet-deleted-webhook',
  'wallet-contact-2': 'wallet-contact-365061',
  'wallet-contact-object': 'wallet-contact-365061',
  'business-details-object': 'wallet-contact-365061',
  'wallet-transaction': 'wallet-transaction-365069',
  'wallet-transaction-object': 'wallet-transaction-365069',
  'wallet-limit-object': 'wallet-transaction-365069',
  'action-data-object': 'wallet-transaction-365069',
  'webhook-funds-added': 'funds-added-webhook',
  'webhook-funds-removed': 'funds-removed-webhook',
  'webhook-transfer-completed': 'transfer-completed-webhook',
  'webhook-transfer-funds': 'transfer-funds-webhook',
  'webhook-transfer-funds-between-wallets-created':
    'transfer-funds-between-wallets-created-webhook',
  'webhook-transfer-funds-between-wallets-expired':
    'transfer-funds-between-wallets-expired-webhook',
  'webhook-transfer-funds-between-wallets-response':
    'transfer-funds-between-wallets-response-webhook',
  'webhook-transfer-request': 'transfer-request-webhook',
  'transaction-limit-object': 'transaction-limit',
  'webhook-transaction-limit-changes-beta':
    'contact-limit-change-webhook---beta',
  'identity-verification': 'identity-verification-365089',
  'identity-verification-object': 'identity-verification-365089',
  'webhook-identity-verification': 'identity-verification-webhook',
  'identity-verification-hosted-page': 'identity-verification---hosted-page',
  'identity-verification-object-hosted-page':
    'identity-verification---hosted-page',
  'virtual-account-number-object': 'virtual-account',
  'webhook-deposit-completed': 'deposit-completed-webhook',
  'virtual-account-number-errors': 'virtual-account-errors',
  'rapyd-verify-1': 'rapyd-verify-365108',
  'rapyd-verify-object': 'rapyd-verify-365108',
  'merchant-details-object': 'rapyd-verify-365108',
  'organization-details-object': 'rapyd-verify-365108',
  'webhook-application-status-change': 'application-status-change-webhook',
  'rapyd-issuing-overview': 'rapyd-issuing-365115',
  'issued-card-object': 'issued-card',
  'webhook-bulk-issued-card-personalized':
    'bulk-issued-card-personalized-webhook',
  'webhook-issued-card-activated': 'issued-card-activated-webhook',
  'webhook-issued-card-pin-set': 'issued-card-pin-set-webhook',
  'webhook-card-issuing-blocked': 'card-issuing-blocked-webhook',
  'card-details-object': 'card-details',
  'rapyd-authorization-object': 'rapyd-authorization',
  'simulate-a-card-refund-eea': 'simulate-a-card-refund---eea',
  'simulate-a-card-refund-non-eea': 'simulate-a-card-refund---non-eea',
  'simulate-a-card-transaction-adjustment-eea':
    'simulate-a-card-transaction-adjustment---eea',
  'simulate-a-card-transaction-adjustment-non-eea':
    'simulate-a-card-transaction-adjustment---non-eea',
  'simulate-a-card-adjustment': 'simulate-a-card-transaction-adjustment---eea',
  'simulate-a-card-refund': 'simulate-a-card-refund---eea',
  'webhook-authorization-declined': 'authorization-declined-webhook',
  'remote-authorization': 'remote-authorization-365140',
  'remote-authorization-object': 'remote-authorization-365140',
  'simulate-a-card-transaction-authorization-request-eea':
    'simulate-a-card-transaction-authorization-request---eea',
  'simulate-a-card-transaction-authorization-request-non-eea':
    'simulate-a-card-transaction-authorization-request---non-eea',
  'simulate-a-card-authorization-request':
    'simulate-a-card-transaction-authorization-request---eea',
  'simulate-a-card-transaction-authorization-reversal-eea':
    'simulate-a-card-transaction-authorization-reversal---eea',
  'simulate-a-card-transaction-authorization-reversal-non-eea':
    'simulate-a-card-transaction-authorization-reversal---non-eea',
  'simulate-a-card-authorization-reversal':
    'simulate-a-card-transaction-authorization-reversal---eea',
  'webhook-remote-authorization-successful':
    'remote-authorization-successful-webhook',
  'webhook-remote-authorization-reversed':
    'remote-authorization-reversed-webhook',
  'issued-card-transaction-object': 'issued-card-transaction',
  'webhook-card-issuing-adjustment': 'card-issuing-adjustment-webhook',
  'webhook-card-issuing-atm-fee': 'card-issuing-atm-fee-webhook',
  'webhook-card-issuing-authorization-approved':
    'card-issuing-authorization-approved-webhook',
  'webhook-card-issuing-refund': 'card-issuing-refund-webhook',
  'webhook-card-issuing-refund': 'card-issuing-refund-webhook',
  'webhook-card-issuing-reversal': 'card-issuing-reversal-webhook',
  'webhook-card-issuing-sale': 'card-issuing-sale-webhook',
  'hosted-card-activation-page-object': 'hosted-card-activation-page',
  'hosted-pin-management-object': 'hosted-pin-management',
  'daily-rate-object': 'daily-rates',
  'list-rapyd-pos-locations-by-coordinates-cluster-based':
    'list-rapyd-pos-locations-by-coordinates---cluster-based',
  'simulating-3ds-authentication-api-method':
    'simulating-3ds-authentication---api-method',
  'simulating-3ds-authentication-hosted-page':
    'simulating-3ds-authentication---hosted-page',
  'point-of-sale-api-overview': 'point-of-sale-guide',
  'deposit-section': 'deposit-and-pay',
  'webhook-payment-completion': 'payment-completion-webhook',
  'webhook-payment-confirmation': 'payment-confirmation-webhook',
  'webhook-deposit-confirmation': 'deposit-confirmation-webhook',
  'webhook-deposit-completed-1': 'deposit-completed-webhook-365170',
  'webhook-withdraw-completed': 'withdraw-completed-webhook',
  'webhook-withdraw-reversal': 'withdraw-reversal-webhook',
  'paycode-object': 'paycodes',
  'details-object': 'paycodes',
  'getting-started': 'get-started',
  'the-rapyd-global-payments-network': 'rapyd-platforms',
  faq: 'payment-payout-method-types',
  'use-case-starting-point': 'use-cases',
  'make-your-first-api-call': 'first-api-call',
  'activate-account-kyb': 'activating-your-account-kyb',
  'rapyd-collect-overview-1': 'rapyd-collect-overview',
  'hosted-checkout-page-integration-steps': 'hosted-checkout-page-integration',
  'checkout-toolkit': 'checkout-toolkit-integration',
  'completing-the-payment-process': 'complete-payment',
  'payment-methods': 'integrate-payment-methods',
  'billing-1': 'billing',
  'mass-payments': 'generating-mass-payments',
  'mass-invoice': 'generating-mass-payments',
  'bank-accounts-issuing': 'virtual-account-numbers',
  'payment-methods-overview': 'payment-types-overview',
  'cash-dominican-republic': 'cash-payments-dominican-republic',
  unionpaycard: 'unionpay',
  payouts: 'rapyd-disburse-overview',
  'rapyd-disburse-payout-methods-1': 'mass-payouts-overview',
  'mass-payouts-overview': 'rapyd-disburse-features',
  wallets: 'rapyd-wallet-overview',
  'verifying-identity': 'identity-verification',
  'issuing-platform': 'rapyd-issuing-overview',
  'pin-management-hosted-issued-card':
    'pin-management-via-hosted-issued-card-page',
  'load-and-drain-authorization': 'load-and-spend-authorization',
  'rapyd-verify-idv-check-swift-payout-update': 'march-2023',
  'client-portal-multiple-merchant-users-address-fields-refund-object':
    'february-2023',
  'rapyd-business-account-reports-subscription-plan-examples': 'january-2023',
  'client-portal-updates-disburse-payment-link-details': 'december-2022',
  'hosted-subscriptions-page-swift-payout-rapyd-verify': 'november-2022',
  'client-portal-updates-reports-rotate-api-keys': 'october-2022',
  'sepa-direct-debit-languages-redesign': 'september-2022',
  'payment-links-reports-settings-plugins-page': 'august-2022',
  'payment-method-type-object-va-confirmation': 'july-2022',
  'hosted-checkout-page-cards-save-table-filters': 'june-2022',
  'rapyd-support-google-pay-toolkit-integration-escrow': 'may-2022',
  'google-pay-virtual-accounts': 'april-2022',
  'virtual-accounts-apple-pay': 'march-2022',
  'hosted-idv-beneficiary-account-details-hosted-card-activation-page':
    'february-2022',
  'client-portal-updates-rapyd-verify-toolkit-styling': 'january-2022',
  'apple-pay-and-card-to-card-payments': 'december-2021',
  'payment-payout-method-request-list-languages-and-reporting': 'november-2021',
  'plugins-and-add-ons-updated-and-wallet-fx': 'october-2021',
  'identity-verification-collect-and-client-portal-updates': 'september-2021',
  'client-portal-updates-foreign-exchange': 'august-2021',
  'client-portal-2fa-sandbox-updates-wallet-contact': 'july-2021',
  'changelog-june-2021': 'june-2021',
  'changelog-may-2021': 'may-2021',
  'changelog-april-2021': 'april-2021',
  'changelog-march-2021': 'march-2021',
  'changelog-february-2021': 'february-2021',
  'changelog-january-2021': 'january-2021',
  'changelog-december-2020': 'product-changelog',
  'changelog-november-2020': 'product-changelog',
  'changelog-october-2020': 'product-changelog',
  'changelog-september-2020': 'product-changelog',
  'august-test': 'product-changelog',
  'rapyd-payments-add-on-for-ecwid': 'rapyd-payments-app-for-ecwid',
  'rapyd-payments-plugin-for-woocommerce-new':
    'rapyd-payments-plugin-for-woocommerce',
  'adding-a-bank-account': 'adding-a-bank-account-365666',
  'save-beneficiary-details': 'save-beneficiary-details-tokenization-file',
  'mass-payments': 'mass-invoices',
  'creating-mass-payments': 'creating-mass-invoices',
  'downloading-a-sample-mass-payment-file':
    'downloading-a-sample-mass-invoice-file',
  'creating-a-mass-payment-file': 'creating-a-mass-invoice-file',
  'uploading-a-mass-payment-file': 'uploading-a-mass-invoice-file',
  'listing-mass-payment-reports': 'listing-mass-invoice-reports',
  'downloading-a-mass-payment-report': 'downloading-a-mass-invoice-report',
  'downloading-a-summary-of-mass-payment-reports':
    'downloading-a-summary-of-mass-invoice-reports',
  'downloading-summary-of-mass-payout-reports':
    'downloading-a-summary-of-mass-payout-reports',
  'merchant-reconciliation-report-v16-beta': 'merchant-reconciliation-report',
  'signing-into-the-rapyd-business-account':
    'signing-in-to-the-rapyd-business-account',
  'my-account-1': 'my-account-383783',
  'viewing-bank-accounts-1': 'viewing-bank-accounts-383786',
  'adding-a-bank-account-1': 'adding-a-bank-account-365666',
  'deleting-a-bank-account-1': 'deleting-a-bank-account-383788',
  'modifying-bank-account-details-1': 'modifying-bank-account-details-383789',
  'managing-my-account-1': 'managing-my-account-383793',
  'm-signing-in-to-the-client-portal': 'signing-in-to-the-client-portal-383796',
  'm-home': 'home',
  'm-collect': 'collect-383798',
  'm-viewing-payments': 'viewing-payments',
  'm-payment-details': 'payment-details',
  'm-using-table-filters': 'using-table-filters',
  'm-refund-details': 'refund-details',
  'm-creating-a-refund': 'create-a-refund',
  'm-listing-customers': 'list-customers',
  'm-creating-customers': 'creating-customers',
  'm-my-account': 'my-account-383806',
  'm-managing-your-account': 'managing-your-account',
  'm-viewing-account-details': 'viewing-account-details',
  'm-viewing-transactions': 'viewing-transactions',
  'm-adding-a-bank-account': 'adding-a-bank-account-383810',
  'm-quick-actions': 'quick-actions',
  'm-viewing-quick-actions': 'viewing-quick-actions',
  'm-request-a-payment': 'requesting-a-payment',
  'm-transfer-funds': 'transfer-funds',
  'm-virtual-terminal': 'using-the-virtual-terminal',
  'hosted-page-language-support': 'localization-methods',
};

const fullRedirects = {
  'client-support': 'https://support.rapyd.net',
  'creating-a-customer-support-ticket': 'https://support.rapyd.net',
  'viewing-support-tickets': 'https://support.rapyd.net',
  location: 'https://docs.rapyd.net',
  'retrieve-rapyd-pos-location': 'https://docs.rapyd.net',
  'list-rapyd-pos-locations': 'https://docs.rapyd.net',
  'list-rapyd-pos-locations-by-coordinates': 'https://docs.rapyd.net',
  'list-rapyd-pos-locations-by-coordinates-cluster-based':
    'https://docs.rapyd.net',
  'location-errors': 'https://docs.rapyd.net',
  'hosted-page-language-support':
    'https://docs.rapyd.net/en/localization-methods.html',
};

function mapPageNameInPath(path) {
  const page = path.split('.')[0].split('/').slice(-1);

  if (Object.keys(fullRedirects).includes(page.join())) {
    location.href = fullRedirects[page.join()];
  }

  return `/en${path.split('/').slice(1, -1).join('/')}/${
    pageNameMapper[page] || page
  }.html`;
}

document.addEventListener('DOMContentLoaded', function () {
  const { pathname, hash } = new URL(location);
  const hosts = {
    'qapaligo-assets.rapyd.net': '/en/index-en.html',
    'paligo-assets.rapyd.net': '/en/index-en.html',
  };

  const isInRedirects = Object.keys(fullRedirects).includes(
    pathname.split('.')[0].split('/').slice(-1).join()
  );

  if (hash && legacyPrefixes.some((prefix) => pathname.includes(prefix))) {
    location.pathname = mapPageNameInPath(hash.replace('#', ''));
  } else if (legacyPrefixes.some((prefix) => pathname.includes(prefix))) {
    location.pathname = mapPageNameInPath(
      pathname.replace(legacyPrefixesRegExp, '/')
    );
  }
  // local redirect inside Paligo
  // else if (pathname.endsWith('.html') && isInRedirects) {
  //   location.pathname = mapPageNameInPath(pathname);
  // }
  else {
    location.pathname = hosts[location.host] || '';
  }
});
